import React from "react";
import { Loader } from "react-bulma-components";
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.loadingBlock}>
      <div className={styles.loadingMsg}>
        <span>cargando</span>
        <Loader className="inline-loader loader-medium ml-4" />
      </div>
    </div>
  );
};

export default Loading;
