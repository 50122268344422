import React from "react";
import style from "./HomeListCard.module.scss";
import { Link } from "react-router-dom";
import StarRanking from "components/Atoms/StarRanking";

export default function HomeListCard({ data, isAvailable = false }) {
  return (
    <div className={style.filterCard}>
      <div className={`card ${style.homeListCard}`}>
        <div className={style.cardImage}>
          <img alt="" src={data.logo} />
          {isAvailable && (
            <span className={style.available}>¡Disponible Hoy!</span>
          )}
        </div>
        <div className={style.cardHeading}>
          <Link className="card-link" to={`espacio/${data.id}`}>
            {data.name}
          </Link>
          <StarRanking ranking="4" />
        </div>
        <div className={style.cardText}>
          <p>{data.description}</p>
          <span>{data.type}</span>
        </div>
      </div>
    </div>
  );
}
