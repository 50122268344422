import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import {
  Select,
  Field,
  Help
} from "react-bulma-components/lib/components/form";
import { Button, Loader } from "react-bulma-components";
import { useHistory } from "react-router-dom";
import { stringify } from "query-string";
import InputRange from "react-input-range";
import TimePicker from "rc-time-picker";
import "react-input-range/lib/css/index.css";
import "rc-time-picker/assets/index.css";
import RevealingBox from "../../components/Molecules/RevealingBox";
import CheckBoxField from "components/Molecules/CheckBoxField";
import style from "./SearchFilters.module.scss";
import { useGetInitialParams } from "hooks/api.hook";
import { toInteger } from "lodash-es";

function SearchFilterForm({ data, queryData }) {
  const defaults = {
    edifice: queryData.edifice ? toInteger(queryData.edifice) : 0,
    place: queryData.place ? toInteger(queryData.place) : 0,
    minimum_time: queryData.minimum_time
      ? toInteger(queryData.minimum_time)
      : 0,
    capacity: queryData.capacity ? toInteger(queryData.capacity) : 0,
    specific_time: queryData.specific_time
      ? moment(moment().format("YYYY-MM-DD") + " " + queryData.specific_time)
      : moment()
  };
  const { handleSubmit, control, errors } = useForm({
    defaultValues: defaults
  });
  const history = useHistory();
  const [isMinTimeEnabled, SetIsMinTimeEnabled] = useState(
    queryData.isMinTimeEnabled ? queryData.isMinTimeEnabled : false
  );
  const [isSpecTimeEnabled, SetIsSpecTimeEnabled] = useState(
    queryData.isSpecTimeEnabled ? queryData.isSpecTimeEnabled : false
  );
  const [isCapacityEnabled, SetIsCapacityEnabled] = useState(
    queryData.isCapacityEnabled ? queryData.isCapacityEnabled : false
  );
  const [itemsServices, setItemsServices] = useState(
    queryData.services ? queryData.services.split(",") : []
  );

  const onSubmit = async payload => {
    if (itemsServices.length > 0) {
      payload["services"] = itemsServices.join(",");
    } else {
      delete payload["services"];
    }
    if (isMinTimeEnabled) payload["isMinTimeEnabled"] = true;
    if (isSpecTimeEnabled) {
      payload["isSpecTimeEnabled"] = true;
      payload["specific_time"] = moment(payload["specific_time"]).format(
        "HH:mm:ss"
      );
    }
    if (isCapacityEnabled) payload["isCapacityEnabled"] = true;
    history.push(`/busqueda/${stringify(payload)}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Controller
          control={control}
          name="edifice"
          aria-label="edificio"
          className="is-fullwidth"
          rules={{
            required: { value: true }
          }}
          render={field => (
            <Select
              value={field.value}
              onChange={e => field.onChange(e.target.value)}
              className="is-fullwidth"
            >
              <option value="0">Selecciona edificio</option>
              {data.edifices.map(option => (
                <option key={"dataEdifices-" + option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          )}
        />
        {errors.edifice && <Help color="danger">Selecciona edificio</Help>}
      </Field>
      <Field>
        <Controller
          control={control}
          name="place"
          className="is-fullwidth"
          rules={{
            required: { value: true }
          }}
          render={field => (
            <Select
              value={field.value}
              onChange={e => field.onChange(e.target.value)}
              className="is-fullwidth"
            >
              <option value="0">Selecciona tipo de lugar</option>
              {data.places.map(option => (
                <option key={"dataPlaces" + option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          )}
        />
        {errors.place && <Help color="danger">Selecciona tipo de lugar</Help>}
      </Field>
      <fieldset className={style.fieldset}>
        <legend>Más opciones</legend>
        <RevealingBox
          label="Tiempo mínimo"
          isChecked={isMinTimeEnabled}
          onChange={() => SetIsMinTimeEnabled(!isMinTimeEnabled)}
        >
          <Controller
            name="minimum_time"
            control={control}
            aria-label="selecciona un tiempo minimo"
            className="is-fullwidth"
            render={field => (
              <Select
                value={field.value}
                onChange={e => field.onChange(e.target.value)}
                className="is-fullwidth"
              >
                <option value="0">0</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="120">120</option>
              </Select>
            )}
          />
        </RevealingBox>
        <RevealingBox
          label="Horario específico"
          isChecked={isSpecTimeEnabled}
          onChange={() => SetIsSpecTimeEnabled(!isSpecTimeEnabled)}
        >
          <Controller
            control={control}
            name="specific_time"
            aria-label="elije un horario específico"
            className="is-fullwidth"
            render={field => (
              <TimePicker
                placeholder="Selecciona horario"
                value={field.value}
                onChange={e => field.onChange(e)}
                focusOnOpen={true}
                format="HH:mm:ss A"
              />
            )}
          />
        </RevealingBox>
        <RevealingBox
          label="Capacidad"
          isChecked={isCapacityEnabled}
          onChange={() => SetIsCapacityEnabled(!isCapacityEnabled)}
        >
          <Controller
            className="slider is-circle is-fullwidth"
            aria-label="Define la capacidad de aforo"
            control={control}
            name="capacity"
            render={field => {
              return (
                <div className={style.fieldset__inputRange}>
                  <InputRange
                    maxValue={20}
                    minValue={0}
                    value={field.value}
                    onChange={e => field.onChange(e)}
                  />
                </div>
              );
            }}
          />
        </RevealingBox>
        <hr />
        {data?.services &&
          data.services.map((service, index) => {
            return (
              <Controller
                control={control}
                name="services"
                render={field => (
                  <>
                    {itemsServices.includes(service.id.toString()) ? (
                      <CheckBoxField
                        isChecked={field.value}
                        onChange={() => {
                          field.onChange(service.service);
                          let arr = itemsServices;
                          arr.splice(arr.indexOf(service.id.toString()), 1);
                          setItemsServices([...arr]);
                        }}
                        checked={true}
                        label={service.service}
                      />
                    ) : (
                      <CheckBoxField
                        isChecked={field.value}
                        onChange={e => {
                          field.onChange(service.service);
                          e.target.checked &&
                            setItemsServices([
                              ...itemsServices,
                              service.id.toString()
                            ]);
                        }}
                        label={service.service}
                      />
                    )}
                  </>
                )}
                key={`services-${index}`}
              />
            );
          })}
        <Field className="pt-3">
          <Button className="is-fullwidth is-primary" type="submit">
            Buscar
          </Button>
        </Field>
      </fieldset>
    </form>
  );
}

function SearchFilterAsyncWrapper({ queryData }) {
  const { data: response, isLoading } = useGetInitialParams();

  if (isLoading) {
    return (
      <div>
        <Loader className="ml-3" />
      </div>
    );
  }

  return (
    <SearchFilterForm
      queryData={queryData}
      data={{
        edifices: response.edifices.data,
        places: response.places.data,
        services: response.services.data
      }}
    />
  );
}

export default function SearchFilters({ queryData }) {
  return (
    <div className="card">
      <SearchFilterAsyncWrapper queryData={queryData} />
    </div>
  );
}
