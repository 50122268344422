const path = "users/";
const name = "affiliates/";
const placeRate = "rate-space/";
const placeFavorite = "favorite/";
const deleteFavorite = "delete-favorite/";
export default {
  base: path,
  list: `${path}${name}`,
  rate: `${path}${name}${placeRate}`,
  favorite: `${path}${name}${placeFavorite}`,
  delete_favorite: `${path}${name}${deleteFavorite}`
};
