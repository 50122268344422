import React, { useState } from "react";
import AuthForm from "./AuthFormWrapper.Component";
import { recover_password } from "../../api/auth";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import Button from "react-bulma-components/lib/components/button";
import {
  Field,
  Control,
  Label,
  Input,
  Help
} from "react-bulma-components/lib/components/form";
import Loader from "react-bulma-components/lib/components/loader";
import styles from "./AuthForm.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RecoverPassword({ onSubmit, isLoading, goBack }) {
  const { handleSubmit, errors, control } = useForm();

  return (
    <>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        {/*  <Field>
          <Label htmlFor="newPassword">Nueva contraseña</Label>
          <Control>
            <Controller
              as={Input}
              name="newPassword"
              control={control}
              rules={{ required: true }}
              aria-invalid={errors.email ? "true" : "false"}
              defaultValue=""
            />
          </Control>
          {errors.username && (
            <Help color="danger">Ingresa una nueva contraseña.</Help>
          )}
        </Field> */}
        <Field>
          <Label htmlFor="email">Correo electrónico*</Label>
          <Control>
            <Controller
              as={Input}
              name="email"
              control={control}
              rules={{ required: true }}
              aria-invalid={errors.email ? "true" : "false"}
              defaultValue=""
            />
          </Control>
          {errors.username && (
            <Help color="danger">Ingresa tu email correctamente.</Help>
          )}
        </Field>
        <Field className="mt-4 o-h">
          <hr />
          <Button
            className="is-pulled-right"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Accediendo... <Loader className="ml-4" />
              </>
            ) : (
              "Recuperar contraseña"
            )}
          </Button>
        </Field>
      </form>
      <div>
        <button
          className="is-pulled-right as-link mt-2"
          type="button"
          onClick={goBack}
        >
          <small>Regresar al registro</small>
        </button>
      </div>
      <ToastContainer />
    </>
  );
}

export default function RecoverPasswordForm({ setUserData, goBack, ...props }) {
  const [formError, setFormError] = useState("");
  const [mutate, { isLoading, isError }] = useMutation(recover_password, {
    onError: error => {
      if (error) {
        setFormError(
          "El correo electrónico ingresado no se encuentra registrado."
        );
      }
    },
    onSuccess: ({ data }) => {
      toast.success("Hemos enviado un correo para restablecer tu contraseña!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
    }
  });

  const onSubmit = async body => {
    setFormError(null);
    await mutate(body);
  };

  return (
    <div {...props}>
      <AuthForm.Info>
        {isError ? (
          <AuthForm.Error>{formError}</AuthForm.Error>
        ) : (
          <AuthForm.Progress isLoading={isLoading} />
        )}
      </AuthForm.Info>
      <RecoverPassword
        onSubmit={onSubmit}
        isLoading={isLoading}
        goBack={goBack}
      />
    </div>
  );
}
