import API from "./api";
import endpoints from "./URLconstants";

export function listAllEdifices() {
  return API.get(endpoints.edifice.list);
}

export const getEdificeNames = () => API.get(endpoints.edifice.getNames);

export function getEdificeInfo(slug) {
  return API.get(`${endpoints.edifice.list}${slug}`);
}

export function getServices(edifice = null) {
  return API.get(`${endpoints.edifice.services}`, { params: { edifice } });
}

export function getEdificePlaces(placeId, params = null) {
  return API.get(`${endpoints.edifice.list}${placeId}/places`, { params });
}
