import React from "react";
import style from "./Hero.module.scss";
import Container from "react-bulma-components/lib/components/container";

export default function Hero({ children, background, size = "medium" }) {
  return (
    <section
      className={`${style.hero} is-${size}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      {children && (
        <Container className={style.hero__content}>{children}</Container>
      )}
    </section>
  );
}
