import { useState, useEffect } from "react";

export function useIsComponentLoaded() {
  const [loaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return loaded;
}
