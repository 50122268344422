import React, { useState } from "react";
import AuthForm from "./AuthFormWrapper.Component";
import { login } from "../../api/auth";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "react-query";
import Button from "react-bulma-components/lib/components/button";
import {
  Field,
  Control,
  Label,
  Input,
  Help
} from "react-bulma-components/lib/components/form";
import Loader from "react-bulma-components/lib/components/loader";
import styles from "./AuthForm.module.scss";
import InputShowPassword from "components/InputShowPassword/InputShowPassword";

function Login({ onSubmit, isLoading, goToRecoverPassword }) {
  const { handleSubmit, errors, control } = useForm();
  const [showPass, setShowPass] = useState(false);

  const togglePasswordVisiblity = () => {
    setShowPass(!showPass);
  };

  const rulesPassword = {
    required: true
  };

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Label htmlFor="username">Usuario</Label>
        <Control>
          <Controller
            name="username"
            autoComplete="username"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.email ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.username && (
          <Help color="danger">Ingresa tu usuario correctamente.</Help>
        )}
      </Field>
      <FormProvider>
        <InputShowPassword
          control={control}
          autoComplete="password"
          text="Contraseña*"
          name="password"
          validate={errors.password}
          value={showPass}
          togglePasswordVisiblity={togglePasswordVisiblity}
          showPass={showPass}
          rules={rulesPassword}
          textErrors={errors.password?.message}
        />
      </FormProvider>
      <div className="has-text-right">
        <button
          className="as-link mt-2"
          type="button"
          onClick={goToRecoverPassword}
        >
          <small>Olvidé mi contraseña</small>
        </button>
      </div>
      <Field className="mt-4">
        <hr />
        <Button
          className="is-pulled-right"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              Accediendo... <Loader className="ml-4" />
            </>
          ) : (
            "Ingresar"
          )}
        </Button>
      </Field>
    </form>
  );
}

export default function LoginForm({
  setUserData,
  goToRecoverPassword,
  goToHome,
  ...props
}) {
  const [formError, setFormError] = useState(null);
  const [mutate, { isLoading, isError }] = useMutation(login, {
    onError: error => {
      return error.message.includes(401)
        ? setFormError("Usuario y/o contraseña erróneos. Intenta de nuevo.")
        : setFormError("Hubo un problema de conexión. Intenta de nuevo.");
    },
    onSuccess: ({ data }) => {
      setUserData(data);
      goToHome();
    }
  });

  const onSubmit = async body => {
    setFormError(null);
    await mutate(body);
  };

  return (
    <div {...props}>
      <AuthForm.Info>
        {isError ? (
          <AuthForm.Error>{formError}</AuthForm.Error>
        ) : (
          <AuthForm.Progress isLoading={isLoading} />
        )}
      </AuthForm.Info>
      <Login
        onSubmit={onSubmit}
        isLoading={isLoading}
        goToRecoverPassword={goToRecoverPassword}
      />
    </div>
  );
}
