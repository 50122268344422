import API from "./api";
import endpoints from "./URLconstants";

export const getReservation = () => API.get(endpoints.reservation.list);
export const getExpiredReservation = () =>
  API.get(endpoints.reservation.expired);
export const createReservation = (date, [fromTime, toTime], placeId) =>
  API.post(endpoints.reservation.list, {
    date,
    from_time: fromTime,
    to_time: toTime,
    space: placeId
  });

export const cancelReserveApi = async id => {
  API.post(endpoints.reservation.cancel, {
    reservation_id: id
  });
};
