const path = "users/affiliates/";

export default {
  login: "auth-token/",
  signup: path,
  change_password: `${path}change-password/`,
  recover_password: `${path}recover_password/`,
  reset_password: `${path}reset-password/`,
  logout: `${path}logout/`
};
