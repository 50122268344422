import React, { useRef, useState, forwardRef } from "react";
import AuthForm from "./AuthFormWrapper.Component";
import { signup } from "../../api/auth";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "react-query";
import Button from "react-bulma-components/lib/components/button";
import {
  Field,
  Control,
  Label,
  Input,
  Help
} from "react-bulma-components/lib/components/form";
import Loader from "react-bulma-components/lib/components/loader";
import styles from "./AuthForm.module.scss";
import Avatar from "components/Atoms/Avatar";
import InputShowPassword from "components/InputShowPassword/InputShowPassword";

const SignUp = forwardRef(({ onSubmit, isLoading }, ref) => {
  const { handleSubmit, errors, control, getValues } = useForm({
    mode: "onBlur"
  });
  const [photo, setPhoto] = useState(
    "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
  );
  const [showPass, setShowPass] = useState(false);
  const [showIsPass, setShowIsPass] = useState(false);

  const imageHandler = async event => {
    await setPhoto(URL.createObjectURL(event.target.files[0]));
    ref.current.append("photo", event.target.files[0]);
  };

  const togglePasswordVisiblity = () => {
    setShowPass(!showPass);
  };

  const toggleRepeatPassword = () => {
    setShowIsPass(!showIsPass);
  };

  const rulesPassword = {
    required: "Ingresa tu contraseña correctamente.",
    minLength: {
      value: 8,
      message: "Debes ingresar 8 caracteres como mínimo."
    }
  };

  const rulesRepeatPassword = {
    required: "Vuelve a ingresar la contraseña.",
    minLength: {
      value: 8,
      message: "Debes ingresar 8 caracteres como mínimo."
    },
    validate: value =>
      value === getValues()["password"] ? true : "Las contraseñas no coinciden."
  };

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Control>
          <Controller
            name="photo"
            control={control}
            rules={{ required: false }}
            defaultValue={[]}
            render={props => {
              return (
                <div className={"file-input"}>
                  <input
                    id="photo"
                    type="file"
                    name="photo"
                    className={styles.input_file_photo}
                    accept=".png, .jpeg"
                    {...props}
                    value={props.value.filename}
                    onChange={imageHandler}
                  />
                </div>
              );
            }}
          />
        </Control>
        {photo !== "" ? (
          <label className={styles.label_signup} htmlFor="photo">
            <Avatar width={100} height={100} rounded={true} src={photo} />
            <p className={styles.file_name}></p>
          </label>
        ) : (
          <label className={styles.label_signup} htmlFor="photo">
            {"Foto"}
            <p className={styles.file_name}></p>
          </label>
        )}
        {errors.photo && (
          <Help color="danger">Ingresa tu usuario correctamente.</Help>
        )}
      </Field>

      <Field>
        <Label htmlFor="username">Nombre de usuario*</Label>
        <Control>
          <Controller
            name="username"
            autoComplete="username"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.username ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.username && (
          <Help color="danger">Ingresa tu usuario correctamente.</Help>
        )}
      </Field>
      <Field>
        <Label htmlFor="email">Email*</Label>
        <Control>
          <Controller
            name="email"
            autoComplete="email"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.email ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.username && (
          <Help color="danger">Ingresa tu email correctamente.</Help>
        )}
      </Field>
      <Field>
        <Label htmlFor="identifier">DNI*</Label>
        <Control>
          <Controller
            name="identifier"
            autoComplete="DNI"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.dni ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.username && (
          <Help color="danger">Ingresa tu DNI correctamente.</Help>
        )}
      </Field>
      <Field>
        <Label htmlFor="first_name">Nombres</Label>
        <Control>
          <Controller
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            name="first_name"
            autoComplete="first_name"
            control={control}
            rules={{ required: false }}
            aria-invalid={errors.first_name ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.first_name && <Help color="danger">Error en el nombre</Help>}
      </Field>
      <Field>
        <Label htmlFor="last_name">Apellidos</Label>
        <Control>
          <Controller
            name="last_name"
            render={({ value, onChange, onBlur }) => {
              return (
                <Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            autocomplete="off"
            control={control}
            autoComplete="last_name"
            rules={{ required: false }}
            aria-invalid={errors.last_name ? "true" : "false"}
            defaultValue=""
          />
        </Control>
        {errors.last_name && <Help color="danger">Error en el apellido.</Help>}
      </Field>
      <FormProvider>
        <InputShowPassword
          control={control}
          text="Contraseña*"
          name="password"
          autoComplete="password"
          validate={errors.password}
          togglePasswordVisiblity={togglePasswordVisiblity}
          showPass={showPass}
          rules={rulesPassword}
          textErrors={errors.password?.message}
        />
      </FormProvider>
      <FormProvider>
        <InputShowPassword
          control={control}
          autoComplete="validatePassword"
          text="Repetir contraseña*"
          validate={errors.validatePassword}
          name="validatePassword"
          togglePasswordVisiblity={toggleRepeatPassword}
          showPass={showIsPass}
          rules={rulesRepeatPassword}
          textErrors={errors.validatePassword?.message}
        />
      </FormProvider>
      <Field className="mt-4">
        <hr />
        <Button
          className="is-pulled-right"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              Accediendo... <Loader className="ml-4" />
            </>
          ) : (
            "Regístrame"
          )}
        </Button>
      </Field>
    </form>
  );
});

export default function SignUpForm({ setUserData, goToHome, ...props }) {
  const [formError, setFormError] = useState(null);
  const [mutate, { isLoading, isError }] = useMutation(signup, {
    onError: error => {
      switch (error.request.status) {
        case 400:
          setFormError(
            "Ya existe registro con el mismo nombre de usuario o email."
          );
          break;
        default:
          setFormError(
            "Hubo un problema al intentar registrarte. Intenta de nuevo."
          );
      }
    },
    onSuccess: ({ data }) => {
      setUserData(data);
      goToHome();
    }
  });
  const formDataRef = useRef(new FormData());

  const onSubmit = async body => {
    formDataRef.current.append("email", body.email);
    formDataRef.current.append("identifier", body.identifier);
    formDataRef.current.append("first_name", body?.first_name);
    formDataRef.current.append("last_name", body?.last_name);
    formDataRef.current.append("password", body.password);
    formDataRef.current.append("username", body.username);
    formDataRef.current.append("validatePassword", body.validatePassword);

    setFormError(null);
    await mutate(formDataRef.current);
  };

  return (
    <div {...props}>
      <AuthForm.Info>
        {isError ? (
          <AuthForm.Error>{formError}</AuthForm.Error>
        ) : (
          <AuthForm.Progress isLoading={isLoading} />
        )}
      </AuthForm.Info>
      <SignUp onSubmit={onSubmit} isLoading={isLoading} ref={formDataRef} />
    </div>
  );
}
