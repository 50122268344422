import React, { useState, useEffect, useMemo } from "react";
import { Columns } from "react-bulma-components";
import Carousel from "@brainhubeu/react-carousel";
import HomeListCard from "../../components/Cards/HomeListCard";
import FilteredList from "./FilteredList";
import style from "./HomeFilteredList.module.scss";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import { useApiMutation } from "hooks/api.hook";
import Loading from "components/Loading/Loading";

function CarouselAsyncWrapper({
  initialData,
  activeTab,
  apiUrl,
  apiParams = null
}) {
  const [dataOrigin, setDataOrigin] = useState(initialData);
  const [firstLoad, setFirstLoad] = useState(false);
  const [mutate, { isLoading }] = useApiMutation(
    {
      url: apiUrl,
      config: {
        params: {
          ...apiParams.params,
          place_type: !activeTab ? null : activeTab
        }
      }
    },
    {
      onSuccess: ({ data: results }) => {
        setDataOrigin(results.results);
      }
    }
  );

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      mutate();
    }
    //eslint-disable-next-line
  }, [activeTab]);

  const memoizedData = useMemo(() => {
    return dataOrigin.map((item, index) => (
      <div className={style.filterCard} key={"memoizedData-" + index}>
        <HomeListCard data={item} />
      </div>
    ));
  }, [dataOrigin]);

  if (isLoading) {
    return (
      <Columns.Column>
        <Loading />
      </Columns.Column>
    );
  }

  if (!dataOrigin.length) {
    return (
      <Columns.Column>
        <BlockActionAlert
          message="No se han encontrado resultados."
          showAction={false}
        />
      </Columns.Column>
    );
  }

  return (
    <Carousel
      className={style.carousel}
      slidesPerPage={3}
      arrows
      breakpoints={{
        640: {
          slidesPerPage: 1
        }
      }}
    >
      {memoizedData}
    </Carousel>
  );
}

export default function HomeFilteredList({
  title,
  discoverLink = "#",
  data = null,
  fetchQuery,
  fetchMethod,
  tabs,
  isAvailable = false,
  apiUrl,
  apiParams
}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <FilteredList
        tabs={tabs}
        onTabChange={setActiveTab}
        activeTab={activeTab}
        title={title}
        showTabs={data.length}
      >
        <CarouselAsyncWrapper
          initialData={data}
          activeTab={activeTab}
          apiUrl={apiUrl}
          apiParams={apiParams}
        />
      </FilteredList>
    </>
  );
}
