import React, { useContext, useState } from "react";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import StarRanking from "components/Atoms/StarRanking";
import { UIContext } from "contexts/UIContext";
import Modal from "components/Modals/Modal";
import style from "./RankingButton.module.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createReview } from "api/user";

export default function RankingButton({ ranking, apiUrl, spaceId }) {
  const { openPortal, isOpen, Portal } = useContext(UIContext);
  const [dataRanking, setDataRanking] = useState(0);
  const [dataComment, setDataComment] = useState(null);

  const handleReservation = async () => {
    createReview(spaceId, dataRanking, dataComment)
      .then(response => {
        toast.success("Se ha registrado correctamente!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al procesar solicitud!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        });
      });
  };

  const onEvent = value => {
    setDataRanking(value);
  };

  const handleChangeComment = async value => {
    setDataComment(value.target.value);
  };

  return (
    <>
      <Button
        aria-label="votar este espacio"
        title="votar este espacio"
        onClick={openPortal}
      >
        <StarRanking isClickable={false} ranking={ranking} onEvent={onEvent} />
      </Button>
      <Portal>
        <Modal isOpen={isOpen} contentTitle="¿Te ha gustado este lugar?">
          <div className={style.modalContent}>
            <Columns className="pt-2">
              <StarRanking
                isClickable={true}
                ranking={dataRanking}
                onEvent={onEvent}
              />
            </Columns>
            <Columns className="pt-4">
              <textarea
                placeholder="Ingrese un comentario"
                rows={5}
                cols={80}
                onChange={handleChangeComment}
              />
            </Columns>

            <Columns className="pt-4">
              <Button
                onClick={() => handleReservation()}
                className="is-primary"
              >
                Guardar Valoración
              </Button>
            </Columns>
          </div>
        </Modal>
      </Portal>
      <ToastContainer />
    </>
  );
}
