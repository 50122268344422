import React from "react";

import { Container, Section, Loader } from "react-bulma-components";
import styles from "./LoadingWide.module.scss";

const LoadingWide = (isWide = false) => {
  return (
    <Section>
      <Container>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingMsg}>
            <Loader className="loader-big" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default LoadingWide;
