import React from "react";
import style from "./VenueCard.module.scss";
import StarRanking from "components/Atoms/StarRanking";
import Columns from "react-bulma-components/lib/components/columns";
import { Link } from "react-router-dom";

export default function VenueCard({ data }) {
  return (
    <Columns.Column className="is-one-quarter-desktop">
      <div className="card">
        <div className={style.image}>
          <img src={data.logo} alt="" />
        </div>
        <div className={style.details}>
          <Link className="card-link" to={`/espacio/${data.id}`}>
            {data.name}
          </Link>
          <StarRanking ranking={5} />
          <p>{data.description}</p>
          <small>{data.place_type.name}</small>
        </div>
        <div className={style.action}>
          <Link
            className="button is-fullwidth is-primary"
            to={`/espacio/${data.id}`}
          >
            Reservar
          </Link>
        </div>
      </div>
    </Columns.Column>
  );
}
