import React from "react";
import {
  Select,
  Input,
  Label,
  Field
} from "react-bulma-components/lib/components/form";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import { MdSearch } from "react-icons/md";
import style from "./SearchResultsForm.module.scss";

export default function SearchResultsForm() {
  return (
    <Columns className={`is-gapless ${style.searchResults}`}>
      <Columns.Column narrow className="is-hidden-touch">
        <h2>Resultados:</h2>
      </Columns.Column>
      <Columns.Column>
        <Columns className={style.searchGroup}>
          <Columns.Column>
            <Label>
              <small>Ordenar por:</small>
              <Select className="is-small">
                <option>Disponibles más pronto</option>
              </Select>
            </Label>
          </Columns.Column>
          <Columns.Column className="is-narrow">
            <Field className="is-action">
              <Input
                className="is-small"
                placeholder="Buscar espacio"
                aria-label="nombre del espacio"
              />
              <Button className="is-small is-dark" aria-label="Buscar espacio">
                <MdSearch />
              </Button>
            </Field>
          </Columns.Column>
        </Columns>
      </Columns.Column>
    </Columns>
  );
}
