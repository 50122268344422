import React, { useState, memo } from "react";
import Lightbox from "react-image-lightbox";
import style from "./GridGallery.module.scss";

const Thumbnail = memo(
  ({ img, index, isLightBoxOpen, activeImage, showLightBox }) => {
    return (
      <button
        className={style.image}
        onClick={() => showLightBox(index)}
        role="option"
        aria-selected={isLightBoxOpen && index === activeImage}
      >
        <figure>
          <img src={img} alt="" />
        </figure>
      </button>
    );
  },
  (_prevProps, newProps) => {
    return newProps.activeImage !== newProps.index;
  }
);

export default function GridGallery({ data }) {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  function showLightBox(index) {
    setActiveImage(index);
    setIsLightBoxOpen(true);
  }

  return (
    <>
      <div
        className={style.gridGallery}
        role="listbox"
        aria-label="galería de imágenes"
      >
        {data.map((img, index) => (
          <Thumbnail
            img={img}
            index={index}
            key={"data-" - index}
            isLightBoxOpen={isLightBoxOpen}
            activeImage={activeImage}
            showLightBox={showLightBox}
          />
        ))}
      </div>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={data[activeImage]}
          onCloseRequest={() => setIsLightBoxOpen(false)}
        />
      )}
    </>
  );
}
