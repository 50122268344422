import React, { useRef } from "react";
import style from "./FilteredList.module.scss";
import Columns from "react-bulma-components/lib/components/columns";
import TabbedFilter from "../Atoms/TabbedFilter";

export default function FilteredList({
  title,
  endpoint,
  discoverLink = "#",
  children,
  tabs,
  onTabChange,
  activeTab,
  anchorId,
  isLocked,
  showLoadMore = false,
  showTabs = true,
  defaultTab = true
}) {
  const allTabs = useRef([
    ...(defaultTab ? [{ id: 0, name: "Cualquier tipo" }] : []),
    ...tabs
  ]);

  return (
    <div id={anchorId}>
      <Columns className={`is-mobile ${style.filterTitle}`}>
        <Columns.Column narrow className={style.column}>
          <h2>{title || ""}</h2>
        </Columns.Column>
        {showTabs ? (
          <Columns.Column className={style.column}>
            <TabbedFilter
              isLocked={isLocked}
              filters={allTabs.current}
              onSelect={onTabChange}
              activeTab={activeTab}
            />
          </Columns.Column>
        ) : null}
      </Columns>
      <Columns>{children}</Columns>
      {showLoadMore && (
        <Columns>
          <Columns.Column className={style.filterLink}>
            <a href={discoverLink}>Ver más</a>
          </Columns.Column>
        </Columns>
      )}
    </div>
  );
}
