import React from "react";
import { Section, Container } from "react-bulma-components";

export default function BlockWrapper({ children }) {
  return (
    <Section>
      <Container>{children}</Container>
    </Section>
  );
}
