import React from "react";
import { Checkbox, Field } from "react-bulma-components/lib/components/form";
import style from "./CheckBoxField.module.scss";

export default function CheckBoxField({ isChecked, onChange, label, checked }) {
  return (
    <Field className={style.checkField}>
      <Checkbox value={isChecked} onChange={onChange} checked={checked}>
        <span>{label}</span>
      </Checkbox>
    </Field>
  );
}
