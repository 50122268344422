import React, { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { parse } from "query-string";
// import { parse } from "query-string";
// import { props } from "bluebird";
import Section from "react-bulma-components/lib/components/section";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import SearchFilters from "components/Organisms/SearchFilters.jsx";
import SearchResultsForm from "components/Molecules/SearchResultsForm.jsx";
import style from "./Search.module.scss";
// import { Link } from "react-router-dom";
import SearchResultsList from "components/Organisms/SearchResultsList";

import LoadingWide from "components/Loading/LoadingWide";
import { useGetSearchResults, useGetSearchAround } from "hooks/api.hook";

function AsideListItem({ data }) {
  return (
    <div className={style.asideListItem}>
      <figure>
        <img
          alt=""
          src="https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
        />
      </figure>
      <div>
        <a href="/#">Sala de Exposiciones Dr. Barileri Baliero</a>
        <small>Facultad de Agronomía</small>
      </div>
    </div>
  );
}

function AsideList({ title, data }) {
  return (
    <div className={`is-hidden-touch ${style.asideList}`}>
      <h4>{title}</h4>
      <div>{/*  <AsideListItem />
        <AsideListItem /> */}</div>
    </div>
  );
}

export default function Search() {
  const { query } = useParams();
  const history = useHistory();
  const { data: response, isLoading } = useGetSearchResults();

  const queryData = parse(query);

  const { data: responseAround, isLoadingAround } = useGetSearchAround();

  const searchMemo = useMemo(() => {
    return <SearchFilters queryData={queryData} />;
  }, [queryData]);

  if (isLoading) {
    return <LoadingWide />;
  }

  const goToPlace = place => {
    history.push(`/espacio/${place}`, { date: queryData.date });
  };

  return (
    <>
      <Section className={style.sectionAside}>
        <Container>
          <Columns>
            <aside
              className={`column is-one-quarter-tablet ${style.leftAside}`}
            >
              {searchMemo}
              <AsideList />
              <AsideList />
            </aside>
            <Columns.Column>
              <SearchResultsForm />
              <SearchResultsList
                results={response?.data?.results}
                goToPlace={goToPlace}
              />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </>
  );
}
