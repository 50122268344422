import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

const DefaultLayout = props => (
  <>
    <NavBar />
    <main>{props.children}</main>
    <Footer />
  </>
);
export default DefaultLayout;
