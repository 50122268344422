import React from "react";
import { useQuery } from "react-query";
import HomeBookingForm from "../../components/Organisms/HomeBookingForm";
import HomeFilteredList from "../../components/Organisms/HomeFilteredList";
import HomeBuildingList from "../../components/Organisms/HomeBuildingList";
import { Section, Container } from "react-bulma-components";
// import styles from "./Home.module.scss";
import Hero from "../../components/Banners/Hero";
import GMap from "components/Map";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import Loading from "components/Loading/Loading";
import { listAllEdifices } from "api/edifice";
import endpoints from "api/URLconstants";
import LoadingWide from "components/Loading/LoadingWide";
import { formatTime } from "utils/time";
import { useGetInitialParams } from "hooks/api.hook";
import BlockWrapper from "components/Organisms/BlockWrapper";
import WithDataBlock from "components/Organisms/DataBlock";

function EdificesAsyncWrapper({ id = null }) {
  const { data: response, isLoading, isError, refetch } = useQuery(
    "edifices",
    listAllEdifices,
    {
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Section id={id}>
        <Container>
          <Loading />
        </Container>
      </Section>
    );
  }

  if (isError) {
    return (
      <Section>
        <Container>
          <BlockActionAlert
            message="Hubo un problema al intentar cargar edificios."
            actionText="Cargar Edificios"
            action={refetch}
          />
        </Container>
      </Section>
    );
  }

  return (
    <>
      <HomeBuildingList data={response.data.results} />
      <section id="mapa">
        <GMap dataToCoords={response.data.results} />
      </section>
    </>
  );
}

const HomeDataBlock = WithDataBlock(HomeFilteredList);

const Home = ({ isLogged }) => {
  const { data: response, isLoading } = useGetInitialParams();

  if (isLoading) {
    return <LoadingWide />;
  }

  return (
    <>
      <Hero background="https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80">
        <HomeBookingForm
          places={response.places.data}
          edifices={response.edifices.data}
        />
      </Hero>
      {isLogged && (
        <BlockWrapper>
          <HomeDataBlock
            tabs={response?.places?.data}
            title="Tus favoritos"
            apiUrl={endpoints.places.favorites}
            errorMessage="Hubo un problema al cargar los espacios favoritos."
          />
        </BlockWrapper>
      )}
      <BlockWrapper>
        <HomeDataBlock
          tabs={response.places.data}
          title="Con disponibilidad hoy"
          apiUrl={endpoints.availability?.search}
          apiParams={{ params: { date: formatTime(Date.now()) } }}
          errorMessage="Hubo un problema al cargar los espacios disponibles hoy."
        />
      </BlockWrapper>
      <BlockWrapper>
        <section id="espacios">
          <HomeDataBlock
            tabs={response.places.data}
            title="Espacios Populares"
            apiUrl={endpoints.places.popular}
            errorMessage="Hubo un problema al cargar los espacios populares."
          />
        </section>
      </BlockWrapper>
      <BlockWrapper>
        <section id="edificios">
          <EdificesAsyncWrapper />
        </section>
      </BlockWrapper>
    </>
  );
};
export default Home;
