import React, { useState } from "react";
import style from "./Avatar.module.scss";

const Avatar = props => {
  const {
    width,
    height,
    rounded,
    src,
    toggleSidebar = function() {},
    className = ""
  } = props;

  const [previewImage, setPreviewImage] = useState(src);

  const imageHandler = e => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <div
      className={"cursor-pointer"}
      onClick={() => toggleSidebar()}
      onChange={imageHandler}
    >
      {rounded ? (
        <img
          className={style.icon_avatar_children + className}
          style={{ width: width, height: height }}
          src={src}
          alt="profile-rounded"
        />
      ) : (
        <img
          className={className}
          style={{ width: width, height: height }}
          src={src}
          alt="profile-not-rounded"
        />
      )}
    </div>
  );
};

export default Avatar;
