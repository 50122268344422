import React, { useState, useMemo } from "react";
import LoginForm from "./LoginForm.Component";
import userStore from "contexts/userStore";
import AuthForm from "./AuthFormWrapper.Component";
import styles from "./AuthForm.module.scss";
import SignUpForm from "./SignUpForm.Component";
import RecoverPasswordForm from "./RecoverForm.Component";
import NavBar from "../../components/NavBar/NavBar";
import { useHistory, useLocation } from "react-router-dom";
const authPages = ["login", "signup", "recover"];

const Login = () => {
  const [activeTab, setActiveTab] = useState(authPages[0]);
  const { setUserAndToken } = userStore(state => state);
  const history = useHistory();
  const location = useLocation();

  const memoPages = useMemo(() => {
    const goToHome = () => history.push("/");
    const pages = {};

    pages[authPages[0]] = (
      <LoginForm
        role="tabpanel"
        tabIndex="0"
        id={authPages[0]}
        aria-labelledby={authPages[0]}
        setUserData={setUserAndToken}
        goToRecoverPassword={() => {
          setActiveTab(authPages[2]);
        }}
        goToHome={() => {
          const fromPage = location.state?.fromPage;
          if (fromPage) {
            history.replace(fromPage);
          } else {
            goToHome();
          }
        }}
      />
    );

    pages[authPages[1]] = (
      <SignUpForm
        role="tabpanel"
        tabIndex="0"
        id={authPages[1]}
        aria-labelledby={authPages[1]}
        setUserData={setUserAndToken}
        goToHome={goToHome}
      />
    );

    pages[authPages[2]] = (
      <RecoverPasswordForm
        role="tabpanel"
        tabIndex="0"
        id={authPages[2]}
        aria-labelledby={authPages[2]}
        setUserData={setUserAndToken}
        goBack={() => setActiveTab(authPages[0])}
      />
    );

    return pages;
  }, [history, setUserAndToken]);

  return (
    <>
      <NavBar />
      <AuthForm>
        <nav
          className={styles.nav}
          role="tablist"
          aria-label="Formularios de Ingreso o Registro"
        >
          <button
            role="tab"
            aria-selected={activeTab === authPages[0]}
            aria-controls={authPages[0]}
            onClick={() => setActiveTab(authPages[0])}
            tabIndex={activeTab === authPages[0] ? null : -1}
          >
            Ingreso
          </button>
          <button
            role="tab"
            aria-selected={activeTab === authPages[1]}
            aria-controls={authPages[1]}
            onClick={() => setActiveTab(authPages[1])}
            tabIndex={activeTab === authPages[1] ? null : -1}
          >
            Registro
          </button>
        </nav>
        <div>{memoPages[activeTab]}</div>
      </AuthForm>
    </>
  );
};

export default Login;
