import React, { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import style from "./Calendar.module.scss";
import { Columns, Card, Button } from "react-bulma-components";
import Carousel from "@brainhubeu/react-carousel";
import { MdLocationOn, MdWatchLater } from "react-icons/md";
import { FaCalendar } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Separate from "../Atoms/Separate";
import CustomDatePicker from "./CustomDatePicker";
import CustomRadioButtonGroup from "../Buttons/CustomRadioButtonGroup";
/* import seatCoords from "mock/seatCoords";
import mockBlueprint from "../../assets/img/seatsDemo.gif"; */
import Viewer from "components/Blueprint/Viewer";
import endpoints from "api/URLconstants";
import { formatTime } from "utils/time";
import { addDays } from "date-fns";
import Loading from "components/Loading/Loading";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import { useDeviceSize } from "hooks/resizeObserverHook";
import API from "api/api";
import { useIsComponentLoaded } from "hooks/utility.hooks";
import { createReservation } from "api/reservations";
import userStore from "contexts/userStore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function CalendarSchedule({
  selectedDate,
  duration,
  initialSchedule,
  isMobile,
  url,
  onPushDate,
  markedDate
}) {
  const columns = isMobile ? 1 : 5;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [scheduleData, setScheduleData] = useState(() => ({
    ...initialSchedule
  }));
  const [mutate] = useMutation(
    ({ date }) => {
      return API.get(url, {
        params: {
          date,
          single_date: true,
          minutes: duration
        }
      });
    },
    {
      onSuccess: ({ data: results }) => {
        setScheduleData(() => ({ ...scheduleData, ...results }));
      }
    }
  );

  const changeSlide = useCallback(
    slide => {
      if (slide > currentSlide) {
        const lastDay = Object.keys(scheduleData)
          .pop()
          .split("-");
        mutate({
          date: formatTime(
            addDays(new Date(lastDay[0], lastDay[1] - 1, lastDay[2]), 1)
          ),
          duration: 30
        });
        setCurrentSlide(slide);
      }
    },
    [currentSlide, mutate, scheduleData]
  );

  return (
    <Columns.Column
      desktop={{ size: 12 }}
      tablet={{
        size: 12
      }}
      mobile={{ size: 12 }}
      className={style.calendarContent}
    >
      <Carousel
        arrows
        draggable={false}
        onChange={changeSlide}
        slidesPerPage={columns}
        breakpoints={{
          640: {
            slidesPerPage: 1,
            arrows: false,
            arrowLeft: false,
            arrowRight: false
          },
          769: {
            slidesPerPage: 1,
            arrows: false,
            arrowLeft: false,
            arrowRight: false
          },
          960: {
            slidesPerPage: 1,
            arrows: false,
            arrowLeft: false,
            arrowRight: false
          },
          1200: {
            slidesPerPage: 1,
            arrows: false,
            arrowLeft: false,
            arrowRight: false
          }
        }}
      >
        {Object.entries(scheduleData).map(date => (
          <div key={date[0]}>
            <div>
              <h4>{date[0]}</h4>
            </div>
            <div>
              <ul>
                {date[1].map(time => (
                  <li key={Object.keys(time)}>
                    <Button
                      onClick={() => {
                        onPushDate({ hour: Object.keys(time), day: date[0] });
                      }}
                      disabled={Object.values(time).includes("unavailable")}
                      className={`${
                        Object.values(time).includes("unavailable")
                          ? "is-outlined"
                          : " "
                      }
                      ${
                        Object.keys(time)[0].includes(markedDate?.hour) &&
                        date[0].includes(markedDate?.day)
                          ? "is-primary"
                          : " "
                      }`}
                    >
                      {Object.keys(time)[0]}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Carousel>
    </Columns.Column>
  );
}

function AsyncCalendarWrapper({ spaceId, children, selectedDate, duration }) {
  const loaded = useIsComponentLoaded();
  const { isMobile } = useDeviceSize();
  const url = `${endpoints.places.schedule}${spaceId}/schedule`;

  const { data: results, isLoading, isError, refetch } = useQuery(
    [url, selectedDate, duration, isMobile],
    async (fetchUrl, date, estTime, isSingle) => {
      return API.get(fetchUrl, {
        params: {
          date: date,
          minutes: estTime,
          single_date: isSingle ? true : null
        }
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  useEffect(() => {
    if (loaded) {
      refetch();
    }
    //eslint-disable-next-line
  }, [selectedDate]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <BlockActionAlert
        message="Hubo un problema al cargar el horario disponible. Intenta de nuevo."
        actionText="Volver a cargar"
        action={refetch}
      />
    );
  }
  return React.cloneElement(children, {
    ...children.props,
    initialSchedule: results.data,
    selectedDate,
    isMobile,
    duration,
    url
  });
}

const optDuration = [
  {
    value: 30,
    label: "30 min."
    // disabled: true,
  },
  {
    value: 60,
    label: "60 min."
    // disabled: false,
  },
  {
    value: 90,
    label: "90 min."
    // disabled: true,
  },
  {
    value: 120,
    label: "120 min."
    // disabled: false,
  }
];

function Summary({ summary, onGoToLogin }) {
  const isLogged = userStore(state => state.isLogged)();

  const [reservate, { isLoading }] = useMutation(
    () =>
      createReservation(
        summary?.markedDate.day,
        summary?.markedDate.hour[0].split(" - "),
        summary?.markedSpace
      ),
    {
      onSuccess: async () => {
        toast.success("Se ha registrado correctamente!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (error, variables, context) => {
        toast.error("El espacio no se encuentra disponible!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        });
      }
    }
  );
  return (
    <>
      <Columns className={style.summary}>
        <Columns.Column className="is-two-thirds">
          <h4>Tu selección</h4>
          <ul>
            <li>
              <MdLocationOn />
              <strong>{summary.spaceData.edifice_name}</strong>
            </li>
            <li>
              <FaCalendar />
              <span>{summary.markedDate.day}</span>
            </li>
            <li>
              <MdWatchLater />
              <span>{summary.markedDate.hour}</span>
            </li>
          </ul>
        </Columns.Column>
        <Columns.Column>
          <span
            data-for="reservationButton"
            data-tip
            // data-event-off="dbclick"
            // data-iscapture="true"
          >
            <Button
              className="is-primary"
              disabled={!isLogged}
              loading={isLoading}
              onClick={reservate}
            >
              Reservar
            </Button>
          </span>
          {!isLogged && (
            <ReactTooltip
              id="reservationButton"
              delayHide={2000}
              effect="solid"
              bodyMode={false}
              clickable
            >
              <Link onClick={onGoToLogin}>Inicia sesión</Link> para reservar.
            </ReactTooltip>
          )}
        </Columns.Column>
      </Columns>
    </>
  );
}

export const calendarId = "calendar__container";

const Calendar = ({ spaceId, spaceData, dateFromQuery, onGoToLogin }) => {
  const [date, setDate] = useState(() =>
    formatTime(dateFromQuery ? Date.parse(dateFromQuery) : Date.now())
  );
  const [markedDate, setMarkedDate] = useState(null);
  const [markedSpace, setMarkedSpace] = useState(null);
  const [reservationsItem, setReservationsItem] = useState([]);

  const [duration, setDuration] = useState(optDuration[0].value);

  useEffect(() => {
    setMarkedDate(null);
  }, [date, duration]);

  const onClickMarkedSpace = async body => {
    console.log(body);
    setMarkedSpace(body);
    console.log(markedSpace);
  };

  const onClickMarkedDate = async body => {
    setMarkedDate(body);
    setReservationsItem([]);
    const arrayItems = [];
    spaceData.reservations.map(item => {
      if (item.date_reservation === body.day) {
        if (item.from_time + " - " + item.to_time === body.hour[0]) {
          arrayItems.push(item.space_id);
        }
      }
    });
    setReservationsItem(arrayItems);
  };

  const summary = { duration, markedSpace, markedDate, date, spaceData };
  return (
    <>
      <Card className={style.calendarCardContent} id={calendarId}>
        <Columns breakpoint="mobile">
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 11
            }}
          >
            <h2 className={style.subtextSection}>
              Reservar {spaceData.description}
            </h2>
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 10,
              offset: 1
            }}
            className={style.calendarSection}
          >
            <h4>Fecha</h4>
            <Columns.Column
              mobile={{
                size: 12
              }}
              tablet={{ size: 5 }}
              desktop={{
                size: 5
              }}
              className={style.boxDatePicker}
            >
              <CustomDatePicker onSelect={setDate} date={date} />
            </Columns.Column>
            <Separate color={1} />
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 10,
              offset: 1
            }}
            className={style.calendarSection}
          >
            <h4>Duración</h4>
            <p>Este espacio esta disponible en turnos de hasta 2 horas.</p>
            <Columns.Column
              mobile={{
                size: 12
              }}
              tablet={{ size: 12 }}
              desktop={{
                size: 12
              }}
              className={style.boxCustomRadioButtonGroup}
            >
              <CustomRadioButtonGroup
                isColumn={false}
                options={optDuration}
                onPress={setDuration}
                selected={duration}
              />
            </Columns.Column>
            <Separate color={1} />
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 11,
              offset: 1
            }}
            className={style.calendarSection}
          >
            <h4>Horarios disponibles</h4>
            <p>También puedes explorar alternativas en días cercanos.</p>
          </Columns.Column>
        </Columns>
        <AsyncCalendarWrapper
          spaceId={spaceId}
          selectedDate={date}
          duration={duration}
        >
          <CalendarSchedule
            onPushDate={onClickMarkedDate}
            markedDate={markedDate}
          />
        </AsyncCalendarWrapper>
        {markedDate ? (
          <>
            <Columns>
              <Columns.Column
                mobile={{
                  size: 12
                }}
                tablet={{ size: 12 }}
                desktop={{
                  size: 10,
                  offset: 1
                }}
              >
                <Viewer
                  highlightedItem={markedSpace}
                  json={spaceData.data_map}
                  imgSrc={spaceData.blueprint}
                  onPress={onClickMarkedSpace}
                  scale={1.25}
                  reservationsItem={reservationsItem}
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column
                mobile={{
                  size: 12
                }}
                tablet={{ size: 12 }}
                desktop={{
                  size: 10,
                  offset: 1
                }}
                className={style.selectionContent}
              >
                <Card className={style.reservationCard}>
                  <Summary summary={summary} onGoToLogin={onGoToLogin} />
                </Card>
              </Columns.Column>
            </Columns>
          </>
        ) : null}
      </Card>
      <ToastContainer />
    </>
  );
};

export default Calendar;
