import React from "react";
import { Field } from "react-bulma-components/lib/components/form";
import Switch from "@rakuten-rex/switch/Switch";
import style from "./RevealingBox.module.scss";

export default function RevealingBox({
  isChecked = false,
  label,
  children,
  onChange
}) {
  return (
    <>
      <Switch
        id={`switch-${label}`}
        label={label}
        name={label}
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
      />
      {isChecked && <Field className={style.child}>{children}</Field>}
    </>
  );
}
