import React, { useState } from "react";

import Columns from "react-bulma-components/lib/components/columns";
import Section from "react-bulma-components/lib/components/section";
import Container from "react-bulma-components/lib/components/container";
import Button from "react-bulma-components/lib/components/button";
import Notification from "react-bulma-components/lib/components/notification";
import Progress from "react-bulma-components/lib/components/progress";

import styles from "./AuthForm.module.scss";

export default function AuthForm({ children }) {
  return (
    <Section>
      <Container>
        <Columns className={styles.authFormWrapper}>
          <Columns.Column className="is-one-third-tablet">
            <div className={styles.auth}>{children}</div>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
}

const InfoWrapper = ({ children }) => {
  return <div className={styles.infoWrapper}>{children}</div>;
};

const Error = ({ children }) => {
  const [isShown, setIsShown] = useState(true);

  return (
    isShown && (
      <Notification className="is-danger is-light">
        <p>{children}</p>
        <Button remove onClick={() => setIsShown(false)} />
      </Notification>
    )
  );
};

const FormProgress = ({ isLoading }) => {
  return (
    <div>
      {isLoading && <Progress color="primary" size="small" max={100} />}
    </div>
  );
};

AuthForm.Error = Error;
AuthForm.Progress = FormProgress;
AuthForm.Info = InfoWrapper;
