import React from "react";
import style from "./CustomDatePicker.module.scss";
import { Columns } from "react-bulma-components";

import DatePicker, {
  CalendarContainer,
  registerLocale
} from "react-datepicker";
import es from "date-fns/locale/es";
import { formatTime } from "utils/time";

const CustomDatePicker = ({ onSelect, date = Date.now() }) => {
  registerLocale("es", es);
  const MyModalPicker = ({ className, children }) => {
    return (
      <div className={style.modalPicker}>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <Columns.Column className={style.datePicker}>
      <DatePicker
        locale="es"
        selected={new Date(`${date}T00:00:00`)}
        onChange={date => onSelect(formatTime(date))}
        dateFormat="dd-MM-yyyy"
        className="input"
        calendarContainer={MyModalPicker}
        minDate={new Date()}
      />
    </Columns.Column>
  );
};

export default CustomDatePicker;
