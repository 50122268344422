import React from "react";
import style from "./SvgIcon.module.scss";

export default function SvgIcon({ children, size = "medium", ...props }) {
  return (
    <i className={`${style.svgIcon} ${size}`} {...props}>
      {children}
    </i>
  );
}
