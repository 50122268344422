import React from "react";

const FourHundredFourError = () => {
  return (
    <div className="page-wrapper">
      <div className="error-content">
        <div className="d-table">
          <div className="d-tablecell">
            <h1>404</h1>
            <h4>Página no encontrada</h4>
            <p>La página que está buscando no se encuentra disponible</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourHundredFourError;
