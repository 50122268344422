import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import Card from "react-bulma-components/lib/components/card";
import style from "./SpacesCommentCard.module.scss";
import { MdStar } from "react-icons/md";
import Avatar from "../Atoms/Avatar";
import { times } from "lodash-es";

const SpacesCommentCard = props => {
  const { text, stars, date, avatar, user } = props;
  return (
    <Card className={style.commentCard}>
      <Card.Content className={style.commentCardContent}>
        <Columns breakpoint="mobile">
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 12
            }}
            className={style.commentText}
          >
            <p>{text}</p>
          </Columns.Column>

          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 12
            }}
            className={style.commentValoration}
          >
            <div className={style.detailRanking}>
              {times(5, index => {
                return (
                  <i
                    key={index}
                    className={`${
                      index < stars ? style.activeStar : "default"
                    }`}
                  >
                    <MdStar />
                  </i>
                );
              })}
              <span> - {date} </span>
            </div>
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 8
            }}
            tablet={{ size: 6 }}
            desktop={{
              size: 6
            }}
            className={style.avatarCard}
          >
            <div className={style.detailRanking}>
              <Avatar rounded={true} src={avatar} />
              <p>{user}</p>
            </div>
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 4
            }}
            tablet={{ size: 6 }}
            desktop={{
              size: 6
            }}
            className={style.cardReactions}
          ></Columns.Column>
        </Columns>
      </Card.Content>
    </Card>
  );
};

export default SpacesCommentCard;
