import React from "react";
import styles from "./Footer.module.css";

const Footer = props => {
  const updateYear = new Date().getFullYear();
  return (
    <footer className={styles.footerCnt}>
      <p>
        © {updateYear}. All rights reserved. Created by{" "}
        <span>Infobibliotecas</span>
      </p>
    </footer>
  );
};

export default Footer;
