import React, { useMemo } from "react";
import MainRoute from "./routes";

//context
import UIContextProvider from "contexts/UIContext";
import useResizeObserverHook from "hooks/resizeObserverHook";
import "./assets/css/style.scss";
import "../node_modules/@fortawesome/fontawesome-free/css/solid.min.css";

function AppWrapper({ children }) {
  const { ref, isMobile } = useResizeObserverHook();

  const memoWrapper = useMemo(() => {
    return (
      <div className={`App ${isMobile ? "mobile" : "non-mobile"}`} ref={ref}>
        {children}
      </div>
    );
    //eslint-disable-next-line
  }, [isMobile]);

  return memoWrapper;
}

function App() {
  return (
    <UIContextProvider>
      <AppWrapper>
        <MainRoute />
      </AppWrapper>
      <div id="modal-portal"></div>
    </UIContextProvider>
  );
}

export default App;
