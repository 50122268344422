import React, { createContext } from "react";
import usePortal from "react-useportal";

export const UIContext = createContext();

export default function UIContextProvider({ children }) {
  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    bindTo: document && document.getElementById("modal-portal")
  });

  return (
    <UIContext.Provider
      value={{
        openPortal,
        closePortal,
        isOpen,
        Portal
      }}
    >
      {children}
    </UIContext.Provider>
  );
}
