import React from "react";
import Button from "react-bulma-components/lib/components/button";
import style from "./TabbedFilter.module.scss";

export default function TabbedFilter({
  filters,
  onSelect,
  activeTab,
  isLocked
}) {
  return (
    <ul className={`is-hidden-touch ${style.tabbedFilter}`}>
      {filters.map((filter, index) => (
        <li key={"filter-" + index}>
          <Button
            disabled={isLocked}
            className={`is-small ${
              activeTab === filter.id ? style.active : ""
            }`}
            onClick={() => onSelect(filter.id)}
          >
            {filter.name}
          </Button>
        </li>
      ))}
    </ul>
  );
}
