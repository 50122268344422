import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import style from "./Description.module.scss";
import { times } from "lodash-es";

const Description = props => {
  const {
    isMenu,
    isTitle,
    isSubtitle,
    isUnderlineSubitle,
    items,
    hasPhoto,
    hasButton,
    hasPaddingLeft = true,
    hasNotPaddingBottom
  } = props;

  const DescriptionRow = ({ item }) => {
    const { icon, title, isBold, data, isSchedule, isServices, slug } = item;
    return (
      <>
        <Columns.Column
          mobile={{ size: 12 }}
          tablet={{ size: 12 }}
          desktop={{ size: 12 }}
          className={
            hasPaddingLeft
              ? style.columnIsNotMenu
              : hasNotPaddingBottom
              ? style.hasNotPaddingBottom
              : style.columnIsMenu
          }
        >
          <div className={style.formPartHeader}>
            <div>{icon}</div>
            {isBold ? (
              <h4>{title}</h4>
            ) : isMenu ? (
              <a href={slug}>
                <h3>{title}</h3>{" "}
              </a>
            ) : (
              <h3>{title}</h3>
            )}
          </div>
        </Columns.Column>
        <Columns.Column
          mobile={{
            size: 12
          }}
          tablet={{ size: 12 }}
          desktop={{
            size: 12
          }}
          className={style.formPartBody}
        >
          <div>
            {isSchedule
              ? times(data[0].length, index => {
                  return <p key={index}>{data[0][index]}</p>;
                })
              : ""}
          </div>
          {isServices ? (
            <div className={style.formPartServices}>
              {times(data[0].length, index => {
                return (
                  <div key={index}>
                    {" "}
                    {/* {data[index].icon} */} <p>{data[0][index].service}</p>{" "}
                  </div>
                );
              })}{" "}
            </div>
          ) : (
            ""
          )}
          {!isSchedule && !isServices ? <p>{data}</p> : ""}
        </Columns.Column>
      </>
    );
  };

  const BoxTitle = () => {
    return (
      <Columns.Column
        mobile={{
          size: 12
        }}
        tablet={{ size: 12 }}
        desktop={{
          size: 12
        }}
        className={
          hasPhoto ? style.descriptionHeaderWithPhoto : style.descriptionHeader
        }
      >
        <div>{hasPhoto}</div>

        <h1>{isMenu}</h1>
        <h2>{isTitle}</h2>
        <h3>{isUnderlineSubitle}</h3>
        <h4>{isSubtitle}</h4>
      </Columns.Column>
    );
  };

  const BoxCard = () => {
    return (
      <>
        <BoxTitle />
        <Columns.Column
          mobile={{
            size: 12
          }}
          tablet={{ size: hasButton ? 9 : 12 }}
          desktop={{
            size: hasButton ? 9 : 12
          }}
          className={
            hasNotPaddingBottom
              ? hasPhoto
                ? style.boxDataColumnWithPhoto
                : style.boxDataColumn
              : ""
          }
        >
          {times(items.length, index => {
            return <DescriptionRow key={index} item={items[index]} />;
          })}
        </Columns.Column>
        {hasButton ? (
          <Columns.Column
            mobile={{
              size: 12
            }}
            tablet={{ size: 3 }}
            desktop={{
              size: 3
            }}
            className={style.descriptionButton}
          >
            {/*  <h4>¡Todo listo!</h4>
            <Button type="submit" color="primary">
              Reservar
            </Button> */}
          </Columns.Column>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <Columns breakpoint="mobile">
      <BoxCard />
    </Columns>
  );
};

export default Description;
