import { useState } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useParams } from "react-router-dom";
import { props } from "bluebird";
import { getPlaceTypes } from "api/placeTypes";
import { getEdificeNames, getServices } from "api/edifice";
import { search_filter_spaces } from "api/spaces-search";
import { searchAvailable } from "api/availability";
import { toast } from "react-toastify";
import { createFavorite } from "api/user";
import { deleteFavoriteApi } from "api/user";
import endpoints from "api/URLconstants";
import API from "api/api";

const defaultQuerySettings = { refetchOnWindowFocus: false, retry: 1 };
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export function useGetInitialParams() {
  return useQuery(
    "initialParams",
    async () => {
      return await props({
        places: getPlaceTypes(),
        edifices: getEdificeNames(),
        services: getServices()
      });
    },
    {
      ...defaultQuerySettings,
      staleTime: twentyFourHoursInMs
    }
  );
}

export function useGetSearchResults() {
  const { query } = useParams();
  return useQuery(
    [endpoints.places.spacesSearch, query],
    search_filter_spaces,
    defaultQuerySettings
  );
}

export function useGetSearchAround() {
  const { query } = useParams();

  return useQuery(
    [endpoints.availability.edificesAround, query],
    searchAvailable,
    defaultQuerySettings
  );
}

function apiFetch(url, config) {
  return API(url, config);
}

export function useApiQuery(url, config) {
  return useQuery([url, config], apiFetch, defaultQuerySettings);
}

export function useApiMutation(apiConfig, mutationRules) {
  const { url, config } = apiConfig;
  return useMutation(() => apiFetch(url, config), mutationRules);
}

export const useSetFavorite = (handler = () => {}) => {
  const [handleFavorite] = useMutation(id => createFavorite(id), {
    onSuccess: async () => {
      toast.success("Se ha añadido el favorito correctamente!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      handler();
    },
    onError: (error, variables, context) => {
      toast.error("Ha ocurrido un error al añadir el favorito!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
    }
  });

  return { handleFavorite };
};

export const useDeleteFavorite = (handler = () => {}) => {
  const [deleteFavorite, mutate] = useMutation(id => deleteFavoriteApi(id), {
    onSuccess: async () => {
      toast.success("Se ha elimnado el favorito correctamente!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      handler();
    },
    onError: (error, variables, context) => {
      toast.error("Ha ocurrido un error al eliminar el favorito!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
    }
  });

  return { deleteFavorite, mutate };
};
