const url = "spaces/";
const name = "availability/";

export default {
  base: `${url}`,
  name,
  search: `${url}${name}`,
  twoDaysAround: `${url}${name}two_days_around/`,
  edificesAround: `${name}edifices_around/`
};
