import { useState, useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { debounce } from "lodash-es";
import { useWindowWidth } from "@react-hook/window-size/throttled";

export default function useResizeObserverHook() {
  const [size, setSize] = useState({});
  const onResize = useMemo(() => debounce(setSize, 60, { leading: true }), []);
  const { ref } = useResizeObserver({ onResize });

  return { ref, isMobile: size.width < 768 };
}

export function useDeviceSize() {
  const windowWidth = useWindowWidth();
  return { isMobile: windowWidth < 640, isTablet: windowWidth < 1024 };
}
