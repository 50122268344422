import React from "react";
import { Notification, Button, Columns } from "react-bulma-components";
//styles
import style from "./BlockActionAlert.module.scss";

export default function BlockActionAlert({
  action,
  actionText = "",
  message = "",
  showAction = true
}) {
  return (
    <Notification className={`is-warning is-light ${style.blockAction}`}>
      <Columns>
        <Columns.Column className={style.alertText}>
          <p>{message}</p>
        </Columns.Column>
        {showAction && (
          <Columns.Column className="is-narrow">
            <Button onClick={action} color="primary">
              {actionText}
            </Button>
          </Columns.Column>
        )}
      </Columns>
    </Notification>
  );
}
