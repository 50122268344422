import API from "./api";
import endpoints from "./URLconstants";

export function login(query) {
  return API.post(endpoints.auth.login, query);
}

export function signup(query) {
  return API.post(endpoints.auth.signup, query, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function change_password(query) {
  return API.post(endpoints.auth.change_password, query);
}

export function recover_password(query) {
  return API.post(endpoints.auth.recover_password, query);
}

export function reset_password(payload) {
  return API.post(
    endpoints.auth.reset_password +
      `?uid=${payload.uuid}&token=${payload.token}`,
    payload.query
  );
}
