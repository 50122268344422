import React from "react";
import SVG from "components/SVG";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import style from "../BluePrint.module.scss";

export default function Viewer({
  showSVG = true,
  json,
  imgSrc,
  onPress,
  highlightedItem,
  scale = 1,
  reservationsItem = []
}) {
  return (
    <TransformWrapper scale={scale}>
      <TransformComponent>
        <div className={style.zoomInner}>
          {showSVG ? (
            <SVG
              reservationsItem={reservationsItem}
              json={json}
              onPress={onPress}
              highlightedItem={highlightedItem}
            />
          ) : null}
          <img alt="" src={imgSrc} />
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
}
