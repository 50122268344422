import React, { useRef } from "react";
import { Tooltip } from "react-svg-tooltip";
import style from "./svg.module.scss";

export default function SVG({
  reservationsItem = [],
  json,
  highlightedItem,
  onPress = () => false
}) {
  const parentRef = useRef(null);
  const ref = useRef(null);
  const refs =
    !!json.children && [...Array(json.children.length)].map(el => ref);
  return (
    <svg className={style.svg} ref={parentRef} {...json.attributes}>
      {!!json.children &&
        refs.map((ref, index) => {
          const {
            attributes: { class: className, ...attrs },
            children,
            name,
            type,
            value,
            ...rest
          } = json.children[index];

          return (
            <g key={`${attrs.id}-${index}`}>
              {React.createElement(name, {
                ...attrs,
                ...rest,
                className: `${
                  highlightedItem === attrs["data-name"] ? style.marked : ""
                }  ${
                  reservationsItem.includes(attrs["data-name"])
                    ? style.occupy
                    : ""
                }`,
                ref,
                onClick: () => onPress(attrs["data-name"])
              })}
              <Tooltip triggerRef={ref}>
                <foreignObject
                  className={style.tooltip}
                  x={24}
                  y={24}
                  width={200}
                  height={60}
                >
                  <p>{`Espacio ${attrs["data-name"]}`}</p>
                </foreignObject>
              </Tooltip>
            </g>
          );
        })}
    </svg>
  );
}
