import React from "react";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import Loading from "components/Loading/Loading";
import { useApiQuery } from "hooks/api.hook";

export default function WithDataBlocks(Component) {
  return function DataBlock({
    tabs,
    apiUrl,
    apiParams = {},
    errorMessage,
    title,
    hasSearch = false
  }) {
    const { data: results, isLoading, isError, refetch } = useApiQuery(
      apiUrl,
      apiParams
    );

    if (isLoading) {
      return <Loading />;
    }

    if (isError) {
      return (
        <BlockActionAlert
          message={errorMessage}
          actionText="Volver a cargar"
          action={refetch}
        />
      );
    }

    return (
      <Component
        title={title}
        data={results.data.results}
        tabs={tabs}
        apiUrl={apiUrl}
        apiParams={apiParams}
      />
    );
  };
}
