export const BASE_URL = process.env.REACT_APP_API_URL_V1;

export const configSlider = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  swipeToSlide: true,
  className: "center slider variable-width",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1143,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 607,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
