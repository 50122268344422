import React, { useState, useEffect } from "react";
import { MdStar } from "react-icons/md";
import { times } from "lodash-es";
import style from "./StarRanking.module.scss";

export default function StarRanking({ isClickable, ranking, onEvent }) {
  const [dataRanking, setDataRanking] = useState(0);

  useEffect(() => {
    setDataRanking(ranking);
  }, [ranking]);

  const onHandleStart = async num => {
    setDataRanking(num + 1);
    onEvent(num + 1);
  };
  return (
    <div className={style.cardRanking}>
      {times(5, num => {
        return (
          <i
            className={`${num < dataRanking ? style.activeStar : "default"}`}
            key={num}
          >
            {isClickable ? (
              <MdStar onClick={() => onHandleStart(num)} />
            ) : (
              <MdStar />
            )}
          </i>
        );
      })}
      <span>({dataRanking})</span>
    </div>
  );
}
