import React, { useContext, useEffect, useState } from "react";

import style from "./Profile.module.scss";

//api
import { useMutation, useQuery } from "react-query";
import { getFavorites, getProfile, modifyAccount } from "api/user";
import {
  getReservation,
  getExpiredReservation,
  cancelReserveApi
} from "api/reservations";
import userStore from "contexts/userStore";
import { UIContext } from "contexts/UIContext";
//ui
import Section from "react-bulma-components/lib/components/section";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import Card from "react-bulma-components/lib/components/card";
import Button from "react-bulma-components/lib/components/button";

import { IoMdHeart } from "react-icons/io";
import { FaUserCircle, FaTicketAlt } from "react-icons/fa";
import { MdDelete, MdLocationOn, MdWatchLater } from "react-icons/md";
import { FaCalendar } from "react-icons/fa";

import Description from "../../components/Description/Description";
import Separate from "../../components/Atoms/Separate";
import Avatar from "../../components/Atoms/Avatar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modals/Modal";
import {
  Field,
  Label,
  Input,
  Help,
  Control
} from "react-bulma-components/lib/components/form";
import { Controller, useForm } from "react-hook-form";
import Loader from "react-bulma-components/lib/components/loader";
import { change_password } from "../../api/auth";
import usePortal from "react-useportal";
import { useDeleteFavorite } from "hooks/api.hook";

const Profile = () => {
  const [photo, setPhoto] = useState("");
  const [isFavorites, setIsFavorites] = useState([]);
  const [error, setFormError] = useState("");
  const { handleSubmit, errors, control, getValues } = useForm();
  const {
    openPortal: openPortalPassword,
    closePortal: closePortalPassword,
    isOpen: isOpenPassword,
    Portal: PortalPassword
  } = usePortal({
    bindTo: document && document.getElementById("modal-portal")
  });
  const {
    openPortal: openPortalProfile,
    closePortal: closePortalProfile,
    isOpen: isOpenProfile,
    Portal: PortalProfile
  } = usePortal();
  const { deleteFavorite } = useDeleteFavorite();

  const user = userStore(state => state.user);
  const { setUser } = userStore(state => state);

  const { data: response } = useQuery(user?.id, getProfile, {
    refetchOnWindowFocus: false,
    onSuccess: res => setUser({ ...res.data })
  });

  const { data: responseA } = useQuery("activeReservation", getReservation, {
    refetchOnWindowFocus: false
  });
  const { data: responseE } = useQuery(
    "expiredReservation",
    getExpiredReservation,
    { refetchOnWindowFocus: false }
  );
  const {} = useQuery("favorite", getFavorites, {
    onSuccess: ({ data }) => {
      console.log(data);
      setIsFavorites(data);
    },
    refetchOnWindowFocus: false
  });

  const dataMenu = [
    {
      icon: <FaTicketAlt color="#3489aa" />,
      title: "Reservas",
      slug: "#reservas"
    },
    {
      icon: <FaUserCircle color="#3489aa" />,
      title: "Perfil",
      slug: "#perfil"
    },
    {
      icon: <IoMdHeart color="#3489aa" />,
      title: "Favoritos",
      slug: "#favoritos"
    },
    {
      /*
      icon: <AiTwotoneSetting color="#3489aa" />,
      title: "Configuración",
      slug: "#configuracion"
    */
    }
  ];

  const reservationData = reservation => [
    {
      icon: <MdLocationOn color="#3489aa" />,
      title: `${reservation.address}`
    },
    {
      icon: <FaCalendar color="#3489aa" />,
      title:
        reservation.date /* `${new Date(reservation.date).toDateString()}` */
    },
    {
      icon: <MdWatchLater color="#3489aa" />,
      title: `${reservation.from_time} - ${reservation.to_time}`
    }
  ];

  const Header = props => {
    const { title } = props;
    return (
      <Columns.Column
        mobile={{ size: 12 }}
        dekstop
        size={{ size: 12 }}
        className={style.headerSection}
      >
        <h2>{title}</h2>
      </Columns.Column>
    );
  };

  const [mutate, { isLoading }] = useMutation(change_password, {
    onError: error => {
      toast.error("Ha ocurrido un error intentalo de nuevo!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
    },
    onSuccess: ({ data }) => {
      toast.success("Se ha cambiado la contraseña correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
      closePortalPassword();
    }
  });

  const [updateProfile, { isLoadingProfile }] = useMutation(modifyAccount, {
    onError: error => {
      toast.error("Ha ocurrido un error intentalo de nuevo!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
    },
    onSuccess: data => {
      toast.success("Se ha modificado correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });

      closePortalProfile();
    }
  });

  const [cancelReserve] = useMutation(id => cancelReserveApi(id), {
    onSuccess: async () => {
      toast.success("Se ha cancelado la reserva exitosamente!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    onError: error => {
      if (error.request.status === 400 || error.request.status === 500) {
        toast.error(
          "Ha ocurrido un error al cancelar reserva, intenta más tarde!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
          }
        );
      }
    }
  });

  const onSubmit = async body => {
    await mutate(body);
  };

  const onSubmitUpdateProfile = async body => {
    const formData = new FormData();
    formData.append("first_name", body?.first_name);
    formData.append("last_name", body?.last_name);
    if (photo !== "") {
      formData.append("photo", body.photo);
    }
    const payload = {
      query: formData,
      id: user?.id
    };
    await updateProfile(payload);
  };

  const CardRow = ({ reservation }) => {
    return (
      <Columns.Column
        mobile={{
          size: 12
        }}
        tablet={{ size: 12 }}
        desktop={{
          size: 12
        }}
        className={style.columnCard}
      >
        <Card className={style.card}>
          <Columns className={style.infoProfile + style.column_profile}>
            <Columns.Column size={3} className={style.column_profile}>
              <div className={style.column_image}>
                <Avatar
                  width={160}
                  src={reservation.logo}
                  className={style.image_reservation}
                />
              </div>
            </Columns.Column>
            <Columns.Column className={style.column_profile}>
              <Description
                isUnderlineSubitle={reservation.place}
                isSubtitle={reservation.name}
                items={reservationData(reservation)}
                hasButton={true}
              />
            </Columns.Column>
          </Columns>
        </Card>
      </Columns.Column>
    );
  };

  const renderReservation = (response, label) =>
    response && response.data.results.length > 0 ? (
      response.data.results.map((reservation, i) => (
        <CardRow key={"CardRow-" + i} reservation={reservation} />
      ))
    ) : (
      <Columns.Column>
        <h5>{label}</h5>
      </Columns.Column>
    );

  return (
    <>
      <Section className={style.sectionData}>
        <Container>
          <div>
            <Columns>
              <Columns.Column mobile={{ size: 12 }} desktop={{ size: 3 }}>
                <Description
                  isMenu={"Mi Cuenta"}
                  hasPaddingLeft={false}
                  items={dataMenu}
                />
              </Columns.Column>
              <Columns.Column
                mobile={{ size: 12 }}
                dekstop
                size={{ size: 9 }}
                className={style.columnData}
              >
                <Header title={"Reservas activas"} />
                <section id="reservas">
                  {responseA && responseA.data.results.length > 0 ? (
                    responseA.data.results.map((response, i) => {
                      return (
                        <Columns.Column
                          mobile={{
                            size: 12
                          }}
                          tablet={{ size: 12 }}
                          desktop={{
                            size: 12
                          }}
                          className={style.columnCard}
                        >
                          <Card className={style.card}>
                            <Columns
                              className={
                                style.infoProfile + style.column_profile
                              }
                            >
                              <Columns.Column
                                size={3}
                                className={style.column_profile}
                              >
                                <div className={style.column_image}>
                                  <Avatar
                                    width={160}
                                    src={response.logo}
                                    className={style.image_reservation}
                                  />
                                </div>
                              </Columns.Column>
                              <Columns.Column className={style.column_profile}>
                                <Description
                                  isUnderlineSubitle={response.place}
                                  isSubtitle={response.name}
                                  items={reservationData(response)}
                                  hasButton={true}
                                />
                              </Columns.Column>
                              <Columns.Column>
                                <Button
                                  className={`${style.cancelReserve} is-medium`}
                                  color="primary"
                                  onClick={() => cancelReserve(response.id)}
                                >
                                  Cancelar reserva
                                </Button>
                              </Columns.Column>
                            </Columns>
                          </Card>
                        </Columns.Column>
                      );
                    })
                  ) : (
                    <Columns.Column>
                      <h5>No tienes reservas activas</h5>
                    </Columns.Column>
                  )}
                </section>
                <Separate />
                <Header title={"Perfil"} />
                <section id="perfil">
                  <Columns className={style.infoProfile}>
                    <Columns.Column
                      desktop={{ size: 6 }}
                      className={style.imageProfile}
                    >
                      <Avatar
                        width={100}
                        height={100}
                        size={"is-128x128"}
                        rounded={true}
                        src={
                          response && response.data?.photo
                            ? response.data?.photo
                            : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                        }
                      />
                      <div className={style.infoUserMeta}>
                        <h2>{response ? response.data?.username : ""}</h2>
                        <p>{response ? response.data?.email : ""}</p>
                      </div>
                    </Columns.Column>
                    <Columns.Column className={style.editProfile}>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={openPortalProfile}
                      >
                        Modificar Perfil
                      </Button>
                    </Columns.Column>
                    <Columns.Column className={style.editProfile}>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={openPortalPassword}
                      >
                        Cambiar contraseña
                      </Button>
                    </Columns.Column>
                  </Columns>
                </section>
                <Separate />
                <Header title={"Favoritos"} />
                <section id="favoritos">
                  {isFavorites &&
                    isFavorites.map((favorites, index) => (
                      <Columns
                        key={"responseFavorite-" + index}
                        className={style.infoProfile}
                      >
                        <Columns.Column
                          desktop={{ size: 12 }}
                          className={style.imageProfile}
                        >
                          <Avatar width={130} src={favorites.logo} />
                          <div className={style.infoUserMeta}>
                            <h2>Edificio: {favorites.edifice_name}</h2>
                            <h3>Place: {favorites.description}</h3>
                            <p>Tipo Place: {favorites.place_type}</p>
                          </div>
                          <span
                            id="icon-id"
                            onClick={() => deleteFavorite(favorites.place_id)}
                          >
                            <MdDelete size="50px" color="grey" />
                          </span>
                        </Columns.Column>
                      </Columns>
                    ))}
                </section>
                <Separate />
                <Header title={"Reservas pasadas"} />
                {renderReservation(responseE, "No tienes reservas pasadas")}
                <Separate />
              </Columns.Column>
            </Columns>
          </div>
        </Container>
        {isOpenPassword && (
          <PortalPassword
            closeOnOutsideClick={true}
            onClose={closePortalPassword}
            closeOnEsc={true}
          >
            <Modal isOpen={isOpenPassword} contentTitle="Cambiar contraseña">
              <div className="modal-content-login">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mediun-login">
                    <Field>
                      <Label htmlFor="new_password">
                        Ingrese Nueva contraseña
                      </Label>
                      <Controller
                        as={Input}
                        name="new_password"
                        type="password"
                        control={control}
                        rules={{
                          required: "Ingresa tu contraseña correctamente.",
                          minLength: {
                            value: 8,
                            message: "Debes ingresar 8 caracteres como mínimo."
                          }
                        }}
                        aria-invalid={errors.new_password ? "true" : "false"}
                        defaultValue=""
                      />
                      {errors.new_password && (
                        <Help color="danger">
                          {errors.new_password.message}
                        </Help>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="new_password2">Repita contraseña</Label>
                      <Controller
                        as={Input}
                        name="new_password2"
                        type="password"
                        control={control}
                        rules={{
                          required: "Vuelve a ingresar la contraseña.",
                          minLength: {
                            value: 8,
                            message: "Debes ingresar 8 caracteres como mínimo."
                          },
                          validate: value =>
                            value === getValues()["new_password"]
                              ? true
                              : "Las contraseñas no coinciden."
                        }}
                        aria-invalid={errors.new_password2 ? "true" : "false"}
                        defaultValue=""
                      />
                      {errors.new_password2 && (
                        <Help color="danger">
                          {errors.new_password2.message}
                        </Help>
                      )}
                    </Field>
                  </div>
                  <hr />
                  <Button
                    className="is-pulled-right"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        Accediendo... <Loader className="ml-4" />
                      </>
                    ) : (
                      "Aceptar"
                    )}
                  </Button>
                  <Button
                    className="is-pulled-left"
                    type="submit"
                    onClick={closePortalPassword}
                  >
                    Cerrar
                  </Button>
                </form>
              </div>
            </Modal>
          </PortalPassword>
        )}
        {isOpenProfile && (
          <PortalProfile>
            <Modal isOpen={isOpenProfile} contentTitle="Modificar perfil">
              <div className="modal-content-login">
                <form onSubmit={handleSubmit(onSubmitUpdateProfile)}>
                  <div className="mediun-login">
                    <Field>
                      <Control>
                        <Controller
                          name="photo"
                          control={control}
                          rules={{ required: false }}
                          defaultValue={[]}
                          render={props => {
                            return (
                              <div className={"file-input"}>
                                <input
                                  id="photo"
                                  type="file"
                                  name="photo"
                                  className={style.input_file_photo}
                                  accept=".png, .jpeg"
                                  {...props}
                                  value={props.value.filename}
                                  onChange={event => {
                                    setPhoto(event.currentTarget.files[0]);
                                    return props.onChange(
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </Control>
                      {photo !== "" ? (
                        <label className={style.label_signup} htmlFor="photo">
                          <Avatar
                            width="6rem"
                            height="6rem"
                            rounded={true}
                            src={URL.createObjectURL(photo)}
                          />
                          <p className={style.file_name}></p>
                        </label>
                      ) : (
                        <label className={style.label_signup} htmlFor="photo">
                          <Avatar
                            width="6rem"
                            height="6rem"
                            rounded={true}
                            src={
                              response && response.data?.photo
                                ? response.data.photo
                                : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                            }
                          />
                          {/* <p className={style.file_name}></p> */}
                        </label>
                      )}
                      {errors.photo && (
                        <Help color="danger">
                          Ingresa la foto correctamente.
                        </Help>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="first_name">Nombres</Label>
                      <Control>
                        <Controller
                          as={Input}
                          name="first_name"
                          control={control}
                          rules={{ required: false }}
                          aria-invalid={errors.first_name ? "true" : "false"}
                          defaultValue={
                            response && response?.data?.first_name
                              ? response.data.first_name
                              : " "
                          }
                        />
                      </Control>
                      {errors.first_name && (
                        <Help color="danger">Error en el nombre</Help>
                      )}
                    </Field>
                    <Field>
                      <Label htmlFor="last_name">Apellidos</Label>
                      <Control>
                        <Controller
                          as={Input}
                          name="last_name"
                          control={control}
                          rules={{ required: false }}
                          aria-invalid={errors.last_name ? "true" : "false"}
                          defaultValue={
                            response && response.data?.last_name
                              ? response.data?.last_name
                              : ""
                          }
                        />
                      </Control>
                      {errors.last_name && (
                        <Help color="danger">Error en el apellido.</Help>
                      )}
                    </Field>
                  </div>
                  <hr />
                  <Button
                    className="is-pulled-right"
                    color="primary"
                    type="submit"
                    disabled={isLoadingProfile}
                  >
                    {isLoadingProfile ? (
                      <>
                        Accediendo... <Loader className="ml-4" />
                      </>
                    ) : (
                      "Aceptar"
                    )}
                  </Button>
                  <Button
                    className="is-pulled-left"
                    type="submit"
                    onClick={closePortalProfile}
                  >
                    Cerrar
                  </Button>
                </form>
              </div>
            </Modal>
          </PortalProfile>
        )}
        <ToastContainer />
      </Section>
    </>
  );
};

export default Profile;
