import auth from "./auth";
import edifice from "./edifice";
import availability from "./availability";
import space from "./space";
import user from "./user";
import reservation from "./reservation";
const base = "spaces/";

const placeBase = "places/";
const placeTypes = "place-types/";
const placeFavorites = "place-favorites/";
const placePopular = "popular/";
const spacesSearch = "spaces-search/";

const places = {
  base: placeBase,
  placeTypes: `${base}${placeTypes}`,
  favorites: `${base}${placeFavorites}`,
  popular: `${base}${placeBase}${placePopular}`,
  schedule: `${base}${placeBase}`,
  spacesSearch: `${base}${spacesSearch}`
};

const endpoints = {
  base,
  auth,
  edifice,
  availability,
  space,
  places,
  user,
  reservation
};

export default endpoints;
