import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import {
  Label,
  Input,
  Help,
  Field
} from "react-bulma-components/lib/components/form";
import styles from "./InputShowPassword.module.css";

const InputShowPassword = ({
  text,
  togglePasswordVisiblity,
  value,
  rules,
  textErrors,
  name,
  validate,
  showPass,
  control,
  autoComplete
}) => {
  const { errors } = useForm({ mode: "onBlur" });

  return (
    <Field>
      <Label htmlFor={name}>{text}</Label>
      <div className={styles.container}>
        <Controller
          control={control}
          autoComplete={autoComplete}
          name={name}
          render={({ value, onChange, onBlur }) => {
            return (
              <Input
                value={value}
                type={showPass ? "text" : "password"}
                onBlur={onBlur}
                onChange={onChange}
              />
            );
          }}
          rules={rules}
          aria-invalid={validate ? "true" : "false"}
          defaultValue=""
        />
        <div className={styles.icon_eye}>
          {value ? (
            <AiFillEyeInvisible onClick={togglePasswordVisiblity} />
          ) : (
            <AiFillEye onClick={togglePasswordVisiblity} />
          )}
        </div>
      </div>
      {validate && <Help color="danger">{textErrors}</Help>}
    </Field>
  );
};

export default InputShowPassword;
