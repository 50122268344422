import create from "zustand";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const authToken = JSON.parse(localStorage.getItem("authToken"));
const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

const persistToken = config => (set, get, api) => {
  const initialState = config(
    args => {
      set(args);
      localStorage.setItem("authToken", JSON.stringify(get().authToken));
      localStorage.setItem("refreshToken", JSON.stringify(get().refreshToken));
      localStorage.setItem("userInfo", JSON.stringify(get().user));
    },
    get,
    api
  );

  return initialState;
};

const [userStore] = create(
  persistToken((set, get) => ({
    user: userInfo,
    authToken,
    refreshToken,
    isLogged: () => !!get().authToken,
    setToken: token => set(state => (state.authToken = token)),
    setUser: userData => set(state => (state.user = userData)),
    setUserAndToken: payload => {
      const { access, refresh, ...rest } = payload;
      set(state => ({
        ...state,
        user: rest,
        authToken: access,
        refreshToken: refresh
      }));
    },
    logout: () => {
      set(state => ({
        ...state,
        user: false,
        authToken: false,
        refreshToken: false
      }));
    }
  }))
);

export default userStore;
